import { AUTH_TOKEN, AUTHORITIES, PERMISSION_TO_ADD } from "../constans/constants";

import { getAccount } from '../api/accountResource';
import { jwtAuthenticate } from '../api/jwtResource.js';
import { jwtDecode } from 'jwt-decode';

let user;

export const loadAccount = async () => {
    if(user) {
        return user;
    }
    const response = await getAccount();
    if(response.data && response.data.username) {
        user = response.data;
        return user;
    }
    return null;
}

export const getUser = () => {
    return user;
}

export const authenticate = async (data) => {
    const response = await jwtAuthenticate(data);
    setAuthToken(response.data.id_token);
    user = await loadAccount();
}

export const logout = () => {
    clearAuthToken();
    user = undefined;
}

export const isAuthenticated = () => {
    return !!user;
}

export const hasAnyAuthority = (authorities) => {
    const userAuthorities = user.authorities;
    if (userAuthorities && userAuthorities.length !== 0) {
        return userAuthorities.map(auth => auth.authority).some(auth => authorities.includes(auth));
      }
      return false;
}

export const hasPermissionToAdd = () => {
    return PERMISSION_TO_ADD && hasAnyAuthority([AUTHORITIES.ADMIN]);
}

export const hasMultiEstablishmentManagement = () => {
    return hasAnyAuthority([AUTHORITIES.ADMIN]);
}

export const isAuthorized = (authorities) => {
    if(!isAuthenticated()) {
        return false;
    }
    if(!authorities || authorities.length === 0) {
        return true;
    }
    return hasAnyAuthority(authorities);
}

export const getAuthToken = () => {
    return localStorage.getItem(AUTH_TOKEN);
}

export const setAuthToken = (token) => {
    return localStorage.setItem(AUTH_TOKEN, token);
}

export const clearAuthToken = () => {
    localStorage.removeItem(AUTH_TOKEN);
}

export const getTokenExpirationDate = (token) => {
  const decodedToken = jwtDecode(token);
  if (!decodedToken.exp) {
      return null;
  }
  const date = new Date(0);
  date.setUTCSeconds(decodedToken.exp);
  return date;
}

export const isTokenExpired = () => {
  const token = getAuthToken();
  if (!token) {
      return true;
  }
  const expirationDate = getTokenExpirationDate(token);
  return expirationDate < new Date();
}
