import restClient from "./config/restConfig";
import { toUrlParameters } from "./config/restUtil";
import { emmit } from "../util/eventEmitter";
import { NOTIFICATION_CHANGE_CHANNEL } from "./config/channels";

export const getNotifications = (criteria) => {
  criteria.username = criteria.gonderici;
  criteria.asistan_ad = criteria.aliciList;
  criteria.ve_baglac = false;
  criteria.asistan_soyad = criteria.aliciList;
  let urlParameters = toUrlParameters(criteria);

  return restClient.get(`/bildirim/query?${urlParameters}`);
};

export const getNotification = async (id) => {
  return restClient.get(`/bildirim/${id}`);
};

export const createNotification = async (data) => {
  const newData = await restClient.post(`/bildirim`, data);
  emmit(NOTIFICATION_CHANGE_CHANNEL);
  return newData;
};

export const updateNotification = async (id, data) => {
  const newData = await restClient.put(`/bildirim/${id}`, data);
  emmit(NOTIFICATION_CHANGE_CHANNEL);
  return newData;
};

export const deleteNotification = async (id) => {
  await restClient.delete(`/bildirim/${id}`);
  emmit(NOTIFICATION_CHANGE_CHANNEL);
};



export const downloadNotification = async (paramFileName, id) => {

  const criteria = {uuid: id};
  
  let urlParameters = toUrlParameters(criteria);
  restClient.get(`/bildirimeklenti/content?${urlParameters}`, { responseType: 'arraybuffer' }).then(response => {
  
    const contentType = response.headers['content-type'];
  
      // Alternatif yol (direkt dosyayı indirmek) için:
  
      // Content-Disposition başlığından dosya adını al
      /*
      const contentDisposition = response.headers['content-disposition'];
  
       let fileName = paramFileName;
  
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (filenameMatch.length > 1) {
          fileName = filenameMatch[1];
        }
      } */
  
    // ArrayBuffer'ı Blob'a çevirme
    const blob = new Blob([response.data], { type: contentType });
  
    // Blob'dan URL oluşturma
    const url = URL.createObjectURL(blob);
  
    // Dosyayı tarayıcıda açma
    window.open(url, '_blank','height=600,width=600');
  
    // Alternatif yol: Dosyayı doğrudan indirmek için
    /* const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click(); */
  
    // URL'yi serbest bırakma ve linki kaldırma
    URL.revokeObjectURL(url);
  
    // Dosyanın direkt indirilmesi için alternatif yol seçilmişse eklenecek
    // document.body.removeChild(link);
  })
  .catch(error => {
    console.error('Error fetching the file:', error);
  });
  
  // Orijinalden sonraki kullanımlar
  
  /* Yöntem - 1
    const url = window.URL.createObjectURL(new Blob([response.data]));
    window.open(url, '_blank');
  */
  
  /* Yöntem - 2 (Son kullanılan)
    console.log('text icerikte problem yok ancak, Binary içerik sorunlu. Binary içerik HTTP üzerinden taşınabiliyor browserda denedik. BLOB çevriminde sorun var.')
    window.open(`bildirimeklenti/content?uuid=${id}`,filename,'width=600,height=480,toolbar=no,menubar=no,resizable=no');
    // window.open(`http://localhost:8000/rest/v1/bildirimeklenti/content?uuid=${id}`,filename,'height=600,width=600');
  */
  
  /* ORİJİNAL KULLANIM
  const criteria = {uuid: id};
  
  let urlParameters = toUrlParameters(criteria);
  const response = restClient.get(`/bildirimeklenti/content?${urlParameters}`);
  
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  
  */
}



