export const DEVICE_CHANGE_CHANNEL = "DEVICE_CHANGE_CHANNEL";

export const EVENT_CHANGE_CHANNEL = "EVENT_CHANGE_CHANNEL";

export const ESTABLISHMENTS_CHANGE_CHANNEL = "ESTABLISHMENTS_CHANGE_CHANNEL";

export const DEPARTMENTS_CHANGE_CHANNEL = "DEPARTMENTS_CHANGE_CHANNEL";

export const OPERATIONS_CHANGE_CHANNEL = "OPERATIONS_CHANGE_CHANNEL";

export const OBSERVATION_CHANGE_CHANNEL = "OBSERVATION_CHANGE_CHANNEL";

export const TREATMENT_CHANGE_CHANNEL = "TREATMENT_CHANGE_CHANNEL";

export const TREATMENT_RESULT_CHANGE_CHANNEL =
  "TREATMENT_RESULT_CHANGE_CHANNEL";

export const USERS_CHANGE_CHANNEL = "USERS_CHANGE_CHANNEL";

export const COMPLICATION_CHANGE_CHANNEL = "COMPLICATION_CHANGE_CHANNEL";

export const COMPLICATION_TREATMENT_CHANGE_CHANNEL =
  "COMPLICATION_TREATMENT_CHANGE_CHANNEL";
export const OBSERVATION_VALUE_CHANGE_CHANNEL =
  "OBSERVATION_VALUE_CHANGE_CHANNEL";

export const ASSISTANT_MESSAGE_CHANGE_CHANNEL = "ASSISTANT_MESSAGE_CHANGE_CHANNEL";

export const LOGS_QUERY_CHANGE_CHANNEL = "LOGS_QUERY_CHANGE_CHANNEL";

export const NOTIFICATION_CHANGE_CHANNEL = "NOTIFICATION_CHANGE_CHANNEL";

export const KUMADIN_CHANGE_CHANNEL = "KUMADIN_CHANGE_CHANNEL";

export const PARMANENT_MEDICINE_CHANGE_CHANNEL =
  "PARMANENT_MEDICINE_CHANGE_CHANNEL";

export const PHYSIOTHREAPTH_CHANGE_CHANNEL = "PHYSIOTHREAPTH_CHANGE_CHANNEL";

export const DIET_CHANGE_CHANNEL = "DIET_CHANGE_CHANNEL";

export const PSYCHOTHREAPTY_CHANGE_CHANNEL = "PSYCHOTHREAPTY_CHANGE_CHANNEL";

export const ASSISTANT_OBSERVATION_PLAN_CHANGE_CHANNEL =
  "ASSISTANT_OBSERVATION_PLAN_CHANGE_CHANNEL";

export const ASSISTANTS_CHANGE_CHANNEL = "ASSISTANTS_CHANGE_CHANNEL";

export const ASSISTANT_INTERNSHIP_DEPARTMENT_CHANGE_CHANNEL = "ASSISTANT_INTERNSHIP_DEPARTMENT_CHANGE_CHANNEL";

export const ASSISTANT_TRAININGS_AND_TASKS_CHANGE_CHANNEL = "ASSISTANT_TRAININGS_AND_TASKS_CHANGE_CHANNEL";

export const ASSISTANT_QUALIFICATION_EXAMS_CHANGE_CHANNEL = "ASSISTANT_QUALIFICATION_EXAMS_CHANGE_CHANNEL";

export const ASSISTANT_TECHNICAL_WORKS_CHANGE_CHANNEL = "ASSISTANT_TECHNICAL_WORKS_CHANGE_CHANNEL";

export const ASSISTANT_ADMINISTRATIVE_DUTIES_CHANGE_CHANNEL = "ASSISTANT_ADMINISTRATIVE_DUTIES_CHANGE_CHANNEL";

export const ORGANIZATION_CHANGE_CHANNEL = "ORGANIZATION_CHANGE_CHANNEL";

export const ASSISTANT_MEMBERSHIP_CHANGE_CHANNEL = "ASSISTANT_MEMBERSHIP_CHANGE_CHANNEL";

export const ASSISTANT_GRANT_CHANGE_CHANNEL = "ASSISTANT_GRANT_CHANGE_CHANNEL";

export const ASSISTANT_INTEREST_CHANGE_CHANNEL = "ASSISTANT_INTEREST_CHANGE_CHANNEL";

export const ASSISTANT_MASTER_THESIS_CHANGE_CHANNEL = "ASSISTANT_MASTER_THESIS_CHANGE_CHANNEL";

export const ASSISTANT_PATENT_CHANGE_CHANNEL = "ASSISTANT_PATENT_CHANGE_CHANNEL";

export const ASSISTANT_ARTICLE_CHANGE_CHANNEL = "ASSISTANT_ARTICLE_CHANGE_CHANNEL";

export const ASSISTANT_DECLARATION_CHANGE_CHANNEL = "ASSISTANT_DECLARATION_CHANGE_CHANNEL";

export const ASSISTANT_PUBLICATION_CHANGE_CHANNEL = "ASSISTANT_PUBLICATION_CHANGE_CHANNEL";

export const ASSISTANT_SCIENTIFIC_COURSE_CHANGE_CHANNEL = "ASSISTANT_SCIENTIFIC_COURSE_CHANGE_CHANNEL";

export const ASSISTANT_SURGICAL_OPERATION_CHANGE_CHANNEL = "ASSISTANT_SURGICAL_OPERATION_CHANGE_CHANNEL";

export const ASSISTANT_SURGICAL_OPERATION_WORK_CHANGE_CHANNEL = "ASSISTANT_SURGICAL_OPERATION_WORK_CHANGE_CHANNEL";

export const OPERATION_WORK_CHANGE_CHANNEL = "OPERATION_WORK_CHANGE_CHANNEL";

export const ASSISTANT_LOG_CHANGE_CHANNEL = "ASSISTANT_LOG_CHANGE_CHANNEL";

export const BRANCH_CHANGE_CHANNEL = "BRANCH_CHANGE_CHANNEL";

export const ROTATION_BRANCHES_CHANGE_CHANNEL = "ROTATION_BRANCHES_CHANGE_CHANNEL";

export const SYSTEM_CONSTANT_CHANGE_CHANNEL = "SYSTEM_CONSTANT_CHANGE_CHANNEL";

export const GENERIC_CONSTANTS_CHANGE_CHANNEL = "GENERIC_CONSTANTS_CHANGE_CHANNEL";

export const ACTIVE_FORM_CHANGE_CHANNEL = "ACTIVE_FORM_CHANGE_CHANNEL";
