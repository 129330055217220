import {
  getUser,
  hasMultiEstablishmentManagement,
} from "../../util/securityUtil";

const addEstablishmentIfRequired = (query) => {
  const user = getUser();
  if (!user || hasMultiEstablishmentManagement()) {
    return query;
  }
  return `${query}&kurumId=${user.tipKurumKurulus.id}`;
};

export const toUrlParameters = (data = {}) => {
  const computedData = {};
  Object.keys(data).forEach((key) => {
    // TODO: Burada sıralama sırasında undefined olanlar filtreleniyor. Daha iyi bir çözüm bulunmalı.
    if(key === "sort" && data[key].includes("undefined")) {
      return;
    }
    const hasValue =
      data[key] !== undefined && data[key] !== null && data[key] !== "";
    if (hasValue) {
      if (typeof data[key].getMonth === "function") {
        computedData[key] = data[key].toISOString();
      } else {
        computedData[key] = data[key];
      }
    }
  });
  const query = Object.entries(computedData)
    .map((e) => e.join("="))
    .join("&");
  return addEstablishmentIfRequired(query);
};

export const fetchAll = async (fetchMethod, criteria) => {
  const firstResult = await fetchMethod(criteria);
  let result = [...firstResult.data.content];
  const otherContents = [];
  for (let i = 2; i <= firstResult.data.totalPages; i++) {
    const promise = fetchMethod({ ...criteria, page: i });
    otherContents.push(promise);
  }
  const otherContentResponses = await Promise.all(otherContents);
  otherContentResponses.forEach((otherContentResponse) => {
    result = [...result, ...otherContentResponse.data.content];
  });
  return result;
};
