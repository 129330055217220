import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CAlert,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { logout } from '../../util/securityUtil';
import { useHistory, useLocation } from 'react-router-dom';
import { resetPassword } from '../../api/accountResource';
import { useTranslation } from 'react-i18next';
import LanguagePicker from '../../components/LanguagePicker/LanguagePicker';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResetPassword = () => {

  useEffect(() => {
    logout();
  }, []);

  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');

  const [completed, setCompleted] = useState(false);
  const [passwordMissMatch, setPasswordMissMatch] = useState(false);
  const { t } = useTranslation();

  let query = useQuery();

  const token = query.get("token");

  let history = useHistory();

  const handleSubmit = async () => {

    if (password !== rePassword) {
      setPasswordMissMatch(true);
      return;
    }
    setPasswordMissMatch(false);
    await resetPassword({ token, password });
    setCompleted(true);
  }

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
        </CRow>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <div className="justify-content-center" style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => history.push("/")}>
                    <img className="img-responsive" src={process.env.PUBLIC_URL + '/assets/img/tkdcd_logo.png'} alt="Logo" />
                  </div>
                  <br />
                  <CForm>
                    <h1>{t('account.reset_password')}</h1>
                    <div
                      className="justify-content-end float-right"
                      style={{ marginTop: "-43px" }}
                    >
                      <LanguagePicker />
                    </div>
                    <p className="text-muted">{t('account.enter_new_password')}</p>
                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type="password" placeholder={t('account.password_placeholder')} autoComplete="current-password" value={password} onChange={e => setPassword(e.target.value)} />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type="password" placeholder={t('account.password_confirm_placeholder')} autoComplete="current-password" value={rePassword} onChange={e => setRePassword(e.target.value)} />
                    </CInputGroup>
                    {
                      passwordMissMatch &&
                      <CAlert color="danger">
                        {t('account.password_not_match')}
                      </CAlert>
                    }
                    {!completed && <CRow>
                      <CCol xs="6">
                        <CButton color="primary" className="px-4" onClick={handleSubmit} disabled={!password}>{t('account.confirm')}</CButton>
                      </CCol>
                    </CRow>
                    }
                    {completed &&
                      <CAlert color="success">
                        <CButton color="link" className="px-0" onClick={() => history.push("/login")}>{t('account.password_has_been_renewed')}</CButton>
                      </CAlert>
                    }
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default ResetPassword;
