import React from 'react';
import { CCard, CCardBody, CCardHeader, CCol, CContainer, CRow } from '@coreui/react';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
    const { t } = useTranslation();
  return (
    <CContainer>
      <CRow className="justify-content-center">
        <CCol md="8">
          <CCard>
	            <CCardHeader> {t("privacy_policy.title")}</CCardHeader>
            <CCardBody>
            <p><strong>{t("privacy_policy.effective_date")}</strong></p>
              <p>{t("privacy_policy.intro")}</p>

              <p><strong>{t("privacy_policy.information_we_collect")}</strong></p>
              <p>{t("privacy_policy.data_we_collect")}</p>
              <ul>
                <li>{t("privacy_policy.identity_information")}</li>
                <li>{t("privacy_policy.educational_information")}</li>
                <li>{t("privacy_policy.interests")}</li>
                <li>{t("privacy_policy.professional_academic_information")}</li>
              </ul>

              <p><strong>{t("privacy_policy.how_we_use_information")}</strong></p>
              <p>{t("privacy_policy.use_information")}</p>

              <p><strong>{t("privacy_policy.data_storage_process")}</strong></p>
              <p>{t("privacy_policy.storage_information")}</p>

              <p><strong>{t("privacy_policy.how_we_share_information")}</strong></p>
              <p>{t("privacy_policy.share_information")}</p>

              <p><strong>{t("privacy_policy.data_security")}</strong></p>
              <p>{t("privacy_policy.security_information")}</p>

              <p><strong>{t("privacy_policy.access_update_data")}</strong></p>
              <p>{t("privacy_policy.access_update_data_description")}</p>

              <p><strong>{t("privacy_policy.children_privacy")}</strong></p>
              <p>{t("privacy_policy.children_privacy_description")}</p>

              <p><strong>{t("privacy_policy.third_party_links")}</strong></p>
              <p>{t("privacy_policy.third_party_links_description")}</p>

              <p><strong>{t("privacy_policy.changes_policy")}</strong></p>
              <p>{t("privacy_policy.changes_policy_description")}</p>

              <p><strong>{t("privacy_policy.contact_us")}</strong></p>
              <p>{t("privacy_policy.contact_email")}</p>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default PrivacyPolicy;