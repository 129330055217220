import restClient from './config/restConfig';
import { toUrlParameters } from './config/restUtil';
import { emmit } from '../util/eventEmitter';
import { ASSISTANTS_CHANGE_CHANNEL } from './config/channels';


export const getAssistants = async (criteria) => {
    let urlParameters = toUrlParameters(criteria);
    return restClient.get(`/asistan/query?${urlParameters}`);
}

export const findAllAssistantsWithoutKurum = async (criteria) => {
  let urlParameters = toUrlParameters(criteria);
  return restClient.get(`/asistan/findAllWithoutKurumQuery?${urlParameters}`);
}

export const getAssistant = async (id) => {
    return restClient.get(`/asistan/${id}`);
}

export const getAssistantByEmail = async (email) => {
  return restClient.get(`asistan/asistan_by_email/${email}`);
}

export const createAssistant  = async (data) => {
    const newData = await restClient.post(`/asistan`, data);
    emmit(ASSISTANTS_CHANGE_CHANNEL);
    return newData;
}

export const updateAssistant  = async (id, data) => {
    const newData = await restClient.put(`/asistan/${id}`, data);
    emmit(ASSISTANTS_CHANGE_CHANNEL);
    return newData;
}

export const deleteAssistant = async (id) => {
    await restClient.delete(`/asistan/${id}`);
    emmit(ASSISTANTS_CHANGE_CHANNEL);
}

export const getLanguageList = async () => {
    return restClient.get(`/asistan/yabancidillistesi`);
}

export const getGorevList = async () => {
    return restClient.get(`/asistan/gorevlistesi`);
}


export const exportAssistantCv = async (id) => {
  const lang = localStorage.getItem("i18nextLng");
    return restClient.get(`/reports/asistan_cv/${id}/${lang}`, {
      responseType: 'arraybuffer',
  });
}

export const exportAssistantSurgicalOperation = async (id) => {
  const lang = localStorage.getItem("i18nextLng");
    return restClient.get(`/reports/cerrahi_operasyon_list/${id}/${lang}`, {
      responseType: 'arraybuffer',
  });
}
  

 
